import styled from "styled-components";

export const InputEl = styled.div`
  width: 100%;
  margin-bottom: 40px;

  p {
    font-size: 14px;
    color: var(--gd);
    text-align: start;
    margin-bottom: 2px;
  }

  div {
    width: 100%;
    border-bottom: 1px solid var(--gd);
    display: flex;
    align-items: center;

    .icon {
      margin-left: 10px;
      margin-right: 15px;
    }

    input {
      width: 100%;
      height: 35px;
      border: none;
      outline: none;
      font-size: 15px;
    }

    select {
      width: 100%;
      height: 35px;
      border: none;
      outline: none;
      font-size: 15px;
    }
  }
`;

export const x = 0;
