import React, { useRef } from "react";
import { InputEl } from "styledComponents/input";
import { ButtonEl } from "styledComponents/button";
import { divs, texts } from "styleConstants";
import BackButton from "components/General/BackButton";
import { useAuthContext } from "contexts/AuthContext/context";
import { editWorkerData, getWorkerById } from "actions/AuthActions";
import toast from "react-hot-toast";
import { validateInputs } from "helpers/requests";
import { useNavigate } from "react-router-dom";
import { uploadSingleImage } from "helpers/images";

// Component
export default function EditProfile() {
  // Variables
  const { authState, authDispatch } = useAuthContext();
  const { worker } = authState;
  const [userImg, setUserImg] = React.useState<any>(null);
  const [disableBtn, setDisableBtn] = React.useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  // Input values
  const [name, setName] = React.useState(worker?.name || "");
  const [phone, setPhone] = React.useState(worker?.phone || "");

  // Edit profile
  async function editProfile() {
    setDisableBtn(true); // Disable btn

    // Validate inputs
    if (!validateInputs([name, phone])) {
      toast.error("Debes llenar todos los campos");
      setDisableBtn(false);
      return;
    }

    // If the user selected an image, update user image
    let newImgData = null;
    if (userImg) {
      const res = await uploadSingleImage(userImg);
      if (!res?.status) {
        toast.error(res?.msg || "Hubo un error subiendo la imágen"); // Show error
        setDisableBtn(false);
        return;
      }
      newImgData = {
        filePath: res?.fileRef?.fullPath,
        fileName: res?.fileRef?.name,
      };
    }

    // Edit worker data
    const resEditWorkerData = await editWorkerData(
      worker?.id,
      name,
      phone,
      newImgData
    );
    if (!resEditWorkerData.success) {
      toast.error(resEditWorkerData.msg); // Show error
      setDisableBtn(false);
      return;
    }

    // Everything succedded
    const resWorkerData = await getWorkerById(worker?.id); // Get new data from db
    if (!resWorkerData.success) {
      toast.error("Hubo un error obteniendo los nuevos datos de usuario");
      setDisableBtn(false);
      return;
    }
    authDispatch({ type: "login", payload: resWorkerData.worker }); // Set new data in context
    toast.success("Usuario actualizado correctamente");
    navigate("/private/profile");
  }

  // Render user image
  function renderUserImg(): string {
    if (userImg) return URL.createObjectURL(userImg);
    if (worker?.user_img) return worker?.user_img;
    return "/imgs/no_user.jpg";
  }

  return (
    <div className={divs.pageContainer}>
      {/* Title */}
      <div className={texts.pageTitle.container}>
        <BackButton />
        <h1 className={texts.pageTitle.h1}>Editar Perfil</h1>
      </div>

      {/* User Img */}
      <img
        src={renderUserImg()}
        className="mt-[20px] w-[150px] h-[150px] object-cover rounded-[50%] mb-[15px]"
        alt="User Img"
      />
      <ButtonEl
        className="sm outlined-gd"
        onClick={() => {
          // @ts-ignore
          inputRef.current.click(); // Open file input
        }}
      >
        Cambiar Imágen
      </ButtonEl>
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }} // It doesn't display
        accept="image/*"
        onChange={(e) => {
          if (e.target.files) setUserImg(e.target.files[0]);
        }}
      />

      {/* Data Container */}
      <div className={divs.inputsContainer}>
        <InputEl>
          <p>Nombre</p>
          <div>
            <input
              type="text"
              placeholder="Escribe aquí"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Teléfono</p>
          <div>
            <input
              type="text"
              placeholder="Escribe aquí"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </InputEl>
      </div>

      {/* Buttons */}
      <ButtonEl type="button" onClick={editProfile} disabled={disableBtn}>
        Guardar Cambios
      </ButtonEl>
    </div>
  );
}
