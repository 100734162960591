import { Link } from "react-router-dom";
import { ButtonEl } from "styledComponents/button";
import { divs, texts } from "styleConstants";
import { useAuthContext } from "contexts/AuthContext/context";

// Component
export default function Profile() {
  // Variables
  const { authState } = useAuthContext();
  const { worker } = authState;

  return (
    <div className={divs.pageContainer}>
      {/* Title */}
      <div className={texts.pageTitle.container}>
        <h1 className={texts.pageTitle.h1}>Mi Perfil</h1>
      </div>

      {/* User Img */}
      <img
        src={worker?.user_img || "/imgs/no_user.jpg"}
        className="mt-[20px] w-[150px] h-[150px] object-cover rounded-[50%]"
        alt="User Img"
      />

      {/* Data Container */}
      <div className={divs.dataContainer}>
        <div className={divs.dataItem}>
          <p className={texts.dataLabel}>Nombre</p>
          <p className={texts.dataValue}>{worker?.name || "Sin información"}</p>
        </div>
        <div className={divs.dataItem}>
          <p className={texts.dataLabel}>Correo Electrónico</p>
          <p className={texts.dataValue}>
            {worker?.email || "Sin información"}
          </p>
        </div>
        <div className={divs.dataItem}>
          <p className={texts.dataLabel}>Phone</p>
          <p className={texts.dataValue}>
            {worker?.phone || "Sin información"}
          </p>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex md:flex-row flex-col items-center">
        <Link to="/private/profile/edit">
          <ButtonEl type="button" className="md:mr-[10px] mb-[10px]">
            Editar Perfil
          </ButtonEl>
        </Link>
        <Link to="/private/profile/change-password">
          <ButtonEl type="button" className="mb-[10px]">
            Cambiar Contraseña
          </ButtonEl>
        </Link>
      </div>
    </div>
  );
}
