import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CheckroomOutlinedIcon from "@mui/icons-material/CheckroomOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import { logout } from "actions/AuthActions";
import { useAuthContext } from "contexts/AuthContext/context";
import { NavLink } from "react-router-dom";
import { navbar } from "styleConstants";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { ButtonEl } from "styledComponents/button";

interface NavbarInterface {
  smallView: boolean;
  showNavbar: boolean;
  setShowNavbar: React.Dispatch<React.SetStateAction<boolean>>;
}

// Component
export default function Navbar({
  smallView,
  showNavbar,
  setShowNavbar,
}: NavbarInterface) {
  // Variables
  const { authDispatch } = useAuthContext();

  // Navbar display
  function setNavbarDisplay() {
    if (!smallView) return { display: "flex" };
    if (showNavbar) return { display: "flex" };
    if (!showNavbar) return { display: "none" };
    return { display: "none" };
  }

  return (
    <div className={navbar.navbar} style={setNavbarDisplay()}>
      {/* Sm close navbar */}
      {smallView && (
        <IconButton
          onClick={() => setShowNavbar(false)}
          sx={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <CloseIcon color="error" />
        </IconButton>
      )}

      <div className={navbar.routes}>
        <img
          src="/imgs/logo-circle.png"
          alt=""
          className="h-36 w-36 object-contain mb-5 mr-2"
        />

        <NavLink
          to="/private/profile"
          onClick={() => {
            if (smallView) setShowNavbar(false);
          }}
          className={({ isActive }) =>
            isActive
              ? `${navbar.route} ${navbar.activeRoute} active`
              : navbar.route
          }
        >
          <AccountCircleOutlinedIcon className={navbar.icon} />
          <p>Mi Perfil</p>
        </NavLink>
        <NavLink
          to="/private/orders"
          onClick={() => {
            if (smallView) setShowNavbar(false);
          }}
          className={({ isActive }) =>
            isActive
              ? `${navbar.route} ${navbar.activeRoute} active`
              : navbar.route
          }
        >
          <ShoppingBasketOutlinedIcon className={navbar.icon} />
          <p>Pedidos</p>
        </NavLink>
        <NavLink
          to="/private/models"
          onClick={() => {
            if (smallView) setShowNavbar(false);
          }}
          className={({ isActive }) =>
            isActive
              ? `${navbar.route} ${navbar.activeRoute} active`
              : navbar.route
          }
        >
          <CheckroomOutlinedIcon className={navbar.icon} />
          <p>Modelos</p>
        </NavLink>

        {/* Sm logout btn */}
        {smallView && (
          <div className="mt-[60px] w-full flex justify-center">
            <ButtonEl
              className="outlined-rd"
              onClick={() => logout(authDispatch)}
            >
              Cerrar Sesión
            </ButtonEl>
          </div>
        )}
      </div>
    </div>
  );
}
