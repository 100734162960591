import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import { ModelType } from "types/Model";

// Get categories
export async function getCategories() {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories`;
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return categories
    return {
      success: true,
      categories: res?.data?.categories || [],
    };
  } catch (error) {
    console.log("GET CATEGORIES ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo las categorías"),
    };
  }
}

// Get subcategories
export async function getSubcategories() {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories/subcategories`;
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return categories
    return {
      success: true,
      subcategories: res?.data?.subcategories || [],
    };
  } catch (error) {
    console.log("GET SUBCATEGORIES ERROR", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error obteniendo las subcategorías"
      ),
    };
  }
}

// Search model
export async function searchModel(query: string) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const params = { query };
    const url = `${server}/clothes/search`;
    const res = await axios.get(url, { params });

    // Check response
    if (!res?.data?.models) throw res;

    // Return models
    return {
      success: true,
      models: res?.data?.models || [],
    };
  } catch (error) {
    console.log("SEARCH MODEL ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un problema con la búsqueda"),
    };
  }
}

// Get initial models
export async function getModels(
  pageAction?: string | null,
  pageDocs?: number | null,
  docNameRef?: string | null,
  filterAvailableClothes?: boolean | null
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/clothes`;

    // Make get request with axios and querys
    const res = await axios.get(url, {
      params: {
        pageAction,
        pageDocs,
        docNameRef,
        filterAvailableClothes,
      },
    });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return models
    return {
      success: true,
      models: res?.data?.clothes || [],
    };
  } catch (error) {
    console.log("GET MODELS ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un problema obteniendo los modelos"),
    };
  }
}

// Get all models
export async function getModelsByCategory(
  category: string,
  filterAvailable?: boolean
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    let url = `${server}/clothes/category/${category}`;
    if (filterAvailable) {
      url += "?filterAvailableClothes=true";
    }
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Get models
    const results = res?.data?.models;
    const subcategories = Object.keys(results);

    // Using a map to avoid duplicates
    const modelsMap = new Map<string, ModelType>();

    subcategories.forEach((subcategory) => {
      results[subcategory].forEach((model: ModelType) => {
        modelsMap.set(model.model_id ?? "", model);
      });
    });

    // Return models
    return {
      success: true,
      models: Array.from(modelsMap.values()) || [],
    };
  } catch (error) {
    console.log("GET MODELS ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un problema obteniendo los modelos"),
    };
  }
}

// Add Model
export async function addModel(
  model: ModelType,
  securityID: string | undefined | null
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/clothes/models/new`;
    const res = await axios.post(url, model, { params: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return model id
    return {
      success: true,
      modelId: res?.data?.id,
    };
  } catch (error) {
    console.log("ADD MODEL ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un problema añadiendo el modelo"),
    };
  }
}

// Edit Model
export async function editModel(
  id: string | null | undefined,
  model: ModelType,
  securityID: string | undefined | null
) {
  try {
    if (model.name) {
      model.name = model.name.trim();
    }
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/clothes/${id}`;
    const res = await axios.put(url, model, { params: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return model id
    return {
      success: true,
    };
  } catch (error) {
    console.log("ADD MODEL ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un problema editando el modelo"),
    };
  }
}

// Get model by id
export async function getModelById(id: string | null | undefined) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/clothes/${id}`;
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return models
    return {
      success: true,
      model: res?.data?.model,
    };
  } catch (error) {
    console.log("GET MODEL ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un problema obteniendo el modelo"),
    };
  }
}

// Add model imgs
export async function addModelImgs(
  id: string | null | undefined,
  imgsData: any,
  securityID: string | undefined | null
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/clothes/model/images/${id}`;
    const res = await axios.post(
      url,
      { imgs_data: imgsData },
      { params: { securityID } }
    );

    // Check response
    if (!checkResponse(res)) throw res;

    // Return models
    return {
      success: true,
    };
  } catch (error) {
    console.log("ADD IMAGES ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error añadiendo las imágenes"),
    };
  }
}

// Order model imgs
export async function orderImages(
  id: string | null | undefined,
  bucketPaths: any,
  securityID: string | undefined | null
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/clothes/model/images/${id}`;
    const res = await axios.put(
      url,
      { bucketPaths },
      { params: { securityID } }
    );

    // Check response
    if (!checkResponse(res)) throw res;

    // Return models
    return { success: true };
  } catch (error) {
    console.log("ORDER IMAGES ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error ordenando las imágenes"),
    };
  }
}

// Delete model img
export async function deleteModelImage(
  id: string | null | undefined,
  bucketPath: string | null | undefined,
  securityID: string | undefined | null
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/clothes/model/images/${id}`;
    const res = await axios.delete(url, {
      data: { bucketPath },
      params: { securityID },
    });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return models
    return { success: true };
  } catch (error) {
    console.log("DELETE IMAGE ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error eliminando la imágen"),
    };
  }
}
