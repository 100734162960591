import React from "react";
import BackButton from "components/General/BackButton";
import { useAppContext } from "contexts/AppContext/context";
import capitalize from "helpers/capitalize";
import { divs, texts } from "styleConstants";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { getSubcategoryName } from "helpers/categories";
import { ButtonEl } from "styledComponents/button";
import { AlertDialog } from "components/General/AlertDialog";
import { editModel } from "actions/ModelsActions";
import { useAuthContext } from "contexts/AuthContext/context";
import toast from "react-hot-toast";

// Styles
const styles = {
  container:
    "w-full flex md:flex-row flex-col-reverse justify-between items-start xl:px-[0px] px-[20px]",
  mainImg:
    "border-[1px] border-solid border-gr5 h-[400px] object-cover object-top w-full",
  allImgs: "mt-[30px] grid grid-cols-3 gap-[10px]",
  smallImg:
    "border-[1px] border-solid border-gr5 w-full h-[100px] object-cover object-top cursor-pointer transition-all duration-200 hover:shadow-[0_0_20px_#e7e7e7]",
  details:
    "md:w-[60%] w-full p-[30px] text-start shadow-[0_5px_15px_#e1e1e1] relative md:mb-[0px] mb-[30px]",
  editModelBtn: "edit-model-view-btn top-[15px] right-[15px]",
  detail: "text-[16px] mb-[10px]",
  label: "font-medium text-gd mr-[10px]",
  title: "text-[25px] font-semibold md:w-full w-[90%]",
  brand: "text-[#858585] text-[19px] mb-[15px]",
  price: "text-[20px] font-medium mb-[10px]",
  description: "text-gr2 text-[14px] mb-[30px]",
  sizes: "mt-[5px] list-none ml-[15px] text-[14px]",
  euros: "mb-5 text-[20px] font-medium",
  archived: "font-medium text-red-700 mr-[10px]",
};

export default function ModelView() {
  // Variables
  const { appState } = useAppContext();
  const { modelOnView: model, categories, subcategories } = appState;
  const [archiveDialogOpen, setArchiveDialogOpen] = React.useState(false);
  const [modelArchived, setModelArchived] = React.useState(isModelArchived());
  const { authState } = useAuthContext();
  const { worker } = authState;
  const [mainImg, setMainImg] = React.useState<string | null | undefined>(
    // The main image starts being the model img
    model?.model_imgs && model?.model_imgs.length > 0 && model.model_imgs[0].url
      ? model.model_imgs[0].url
      : "/imgs/no_img.png"
  );
  const [category] = React.useState(
    // Get the data of the model category
    categories?.find((c) => c.id === model?.category_id)
  );
  const navigate = useNavigate();

  function archiveModel() {
    const model = appState.modelOnView;
    if (model) {
      model.sizes = {};
      model.sizes_in_brand_store = [];
      editModel(model.model_id, model, worker?.id).then((res) => {
        if (res) {
          toast.success("Modelo archivado");
          setModelArchived(true);
        } else {
          toast.error("Error al archivar el modelo");
          setModelArchived(false);
        }
      });
    }
  }

  function isModelArchived() {
    let archived = true;
    if (model?.sizes && Object.keys(model?.sizes).length > 0) {
      archived = false;
    }
    if (model?.sizes_in_brand_store && model?.sizes_in_brand_store.length > 0) {
      archived = false;
    }
    return archived;
  }

  return (
    <div className={divs.pageContainer}>
      <AlertDialog
        open={archiveDialogOpen}
        setOpen={setArchiveDialogOpen}
        title="¿Estás seguro/a?"
        description="Si archivas este modelo, se eliminarán todas
         las tallas dentro del inventario y las tallas de la marca, los clientes no podrán verlo en la lista de modelos."
        agreeText="Archivar"
        agreeAction={() => {
          archiveModel();
          setArchiveDialogOpen(false);
        }}
        disagreeText="Cancelar"
        disagreeAction={() => {
          setArchiveDialogOpen(false);
        }}
        agreeColor="text-red-500"
      ></AlertDialog>
      {/* Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/models" />
        <h1 className={texts.pageTitle.h1}>Detalles de Modelo</h1>
      </div>

      {/* Model Details */}
      <div className={styles.container}>
        {/* Image */}
        <div className="md:w-[35%] w-full flex flex-col">
          {/* Selected Img */}
          <img
            className={styles.mainImg}
            src={mainImg || "/imgs/no_img.png"}
            alt="Modelo"
          />

          {/* All imgs */}
          <div className={styles.allImgs}>
            {model?.model_imgs?.slice(0, 6)?.map((img: any) => (
              <img
                onClick={() => setMainImg(img.url)}
                className={styles.smallImg}
                key={img.bucketPath}
                alt="Modelo"
                src={img.url || "/imgs/no_img.png"}
              />
            ))}
          </div>
          <Link to="/private/models/images/view">
            <button
              type="button"
              className="mt-[15px] text-gr1 text-[14px] hover:text-gd transition-all duration-200"
            >
              Ver Imágenes
            </button>
          </Link>
        </div>

        {/*  Details */}
        <div className={styles.details}>
          <IconButton
            className={styles.editModelBtn}
            onClick={() => navigate("/private/models/edit")}
          >
            <EditIcon />
          </IconButton>

          <h1 className={styles.title}>
            {capitalize(model?.name, false, true) || "Sin información"}
          </h1>
          <p className={styles.brand}>{model?.brand || "Sin información"}</p>
          <p className={styles.price}>
            Créditos: {model?.price || "Sin información"}
          </p>
          <p className={styles.euros}>
            Euros: ${model?.euros || "Sin información"}
          </p>
          <p className={styles.description}>
            {model?.description || "Sin descripción"}
          </p>
          <p className={styles.detail}>
            <span className={styles.label}>Categoría:</span>
            {capitalize(category?.name) || "Sin información"}
          </p>
          <div className={styles.detail}>
            <span className={styles.label}>Subcategorías:</span>
            <ul className={styles.sizes}>
              {model?.subcategories?.map((c) => (
                <li key={c}>
                  <span className="font-medium mr-[6px]">
                    {capitalize(
                      getSubcategoryName(c, subcategories) || "Sin información"
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>Tallas en Inventario:</span>
            {model?.sizes && (
              <ul className={styles.sizes}>
                {Object.keys(model?.sizes).map((key: string) => (
                  <li key={key}>
                    <span className="font-medium mr-[6px]">{key}:</span>{" "}
                    {model?.sizes[key] || 0} piezas
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>Tallas de la Marca:</span>
            <ul className={styles.sizes}>
              {model?.sizes_in_brand_store?.map((key: string) => (
                <li key={key}>
                  <span className="font-medium mr-[6px]">{key}</span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            {modelArchived ? (
              <p className={styles.archived}>EL MODELO ESTÁ ARCHIVADO</p>
            ) : (
              <ButtonEl
                onClick={() => {
                  setArchiveDialogOpen(true);
                }}
              >
                <p>Archivar</p>
              </ButtonEl>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
