import { Alert, CircularProgress } from "@mui/material";
import BackButton from "components/General/BackButton";
import OrderStepper from "components/Orders/OrderStepper";
import { useAppContext } from "contexts/AppContext/context";
import { divs, texts } from "styleConstants";
import React from "react";
import OrderDetails from "components/Orders/OrderDetails";
import OrderProducts from "components/Orders/OrderProducts";
import OrderActions from "components/Orders/OrderActions";
import { OrderType, orderStatusKeys } from "types/Order";
import CustomerOrders from "components/Orders/CustomerOrders";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { getOrderById } from "actions/OrdersAction";
import DeliveryDetails from "components/Orders/DeliveryDetails";
import ReturnDetails from "components/Orders/ReturnDetails";

export default function OrderView() {
  // Variables
  const { orderId } = useParams();
  const { appState, appDispatch } = useAppContext();
  const [loaded, setLoaded] = React.useState(false);
  const { orderOnView: order } = appState;

  const getOrderByIdHandler = async () => {
    setLoaded(false);
    const res = await getOrderById(orderId);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    const orderData: OrderType = { ...res?.order };
    appDispatch({ type: "setOrderOnView", payload: orderData });
    setLoaded(true);
  };

  React.useEffect(() => {
    if (order) {
      setLoaded(true);
    } else getOrderByIdHandler();
    appDispatch({ type: "setPrevOrder", payload: null });
  }, []);

  return loaded ? (
    <div className={`${divs.pageContainer} xl:w-full w-[92%]`}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/orders" />
        <h1 className={texts.pageTitle.h1}>Pedido</h1>
      </div>

      {/* Stepper */}
      {order?.status !== orderStatusKeys.order_canceled && (
        <OrderStepper orderStep={order?.status || ""} />
      )}

      {/* In support alert */}
      {order?.is_in_support && (
        <Alert severity="error" className="w-full mb-[30px]">
          Este pedido está en soporte
        </Alert>
      )}

      {/* Order canceled alert */}
      {order?.status === orderStatusKeys.order_canceled && (
        <Alert severity="error" className="w-full mb-[30px]">
          Este pedido fue cancelado
        </Alert>
      )}

      <div className="w-full flex xl:flex-row xl:justify-between flex-col-reverse">
        {/* Left Side */}
        <div className="xl:w-[63%] w-full">
          {/* Order Details */}
          <OrderDetails order={order} />

          {/* Delivery Details */}
          <DeliveryDetails order={order} />

          {/* Reurn Details */}
          <ReturnDetails order={order} />

          {(order?.status === orderStatusKeys.preparing_order ||
            order?.status === orderStatusKeys.preparing_devolution) && (
            <CustomerOrders customerId={order.user_id} status={order?.status} />
          )}

          {/* Order Products */}
          <OrderProducts productItems={order?.products} />
        </div>

        {/* Right Side */}
        <OrderActions />
      </div>
    </div>
  ) : (
    <div className="w-full h-[80vh] flex items-center justify-center">
      <CircularProgress />
    </div>
  );
}
