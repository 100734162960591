import { ButtonEl } from "styledComponents/button";
import { InputEl } from "styledComponents/input";
import { divs, texts } from "styleConstants";
import BackButton from "components/General/BackButton";
import capitalize from "helpers/capitalize";
import { useAppContext } from "contexts/AppContext/context";
import React from "react";
import { CategoryType, SubcategoryType } from "types/Model";
import CloseIcon from "@mui/icons-material/Close";
import { validateInputs } from "helpers/requests";
import toast from "react-hot-toast";
import { addModel, getModelById } from "actions/ModelsActions";
import { IconButton } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { getSubcategoryData } from "helpers/categories";
import { useAuthContext } from "contexts/AuthContext/context";

// Styles
const styles = {
  subcategoriesList: {
    list: "w-full flex flex-col mt-[-15px] mb-[30px]",
    item: "flex justify-between mb-[5px]",
    text: "text-[15px] text-gr1",
    deleteBtn: "text-rd",
  },
  sizes: {
    container: "w-full",
    row: "w-full flex justify-between mb-[10px] items-end",
    sizeText: "text-[15px]",
    qtyInput:
      "outline-none border-b-[1px] border-b-solid border-b-gr1 w-full px-[5px] mr-[20px] placeholder:text-[14px]",
    inputsContainer: "flex",
    qtyDiv: "flex w-[65px] justify-start justify-between",
    qtyText: "",
  },
};

// Component
export default function AddModel() {
  // Variables
  const { appState, appDispatch } = useAppContext();
  const {
    categories,
    initialModels,
    subcategories: allSubcategories,
  } = appState;
  const { authState } = useAuthContext();
  const { worker } = authState;
  const navigate = useNavigate();

  // Input values
  const [name, setName] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [euros, setEuros] = React.useState("");
  const [category, setCatgory] = React.useState<CategoryType | undefined>(
    undefined
  );
  const [subcategories, setSubcategories] = React.useState<
    Array<SubcategoryType | undefined>
  >([]);
  const [sizes, setSizes] = React.useState<{ [key: string]: number }>({});
  const [brandSizes, setBrandSizes] = React.useState<Set<string>>(new Set());
  const [sizeInput, setSizeInput] = React.useState<string>("");
  const [newBrandSize, setNewBrandSize] = React.useState<string>("");
  const [qtyInput, setQtyInput] = React.useState<string>("");

  // Add Model Handles
  async function addModelHandler() {
    // Validate fields
    if (
      !validateInputs([
        name,
        brand,
        description,
        price,
        euros,
        category,
        subcategories,
        sizes,
      ])
    ) {
      toast.error("Debes llenar todos los campos");
      return;
    }
    if (subcategories?.length <= 0) {
      toast.error("Debes seleccionar por lo menos una subcategoría");
      return;
    }

    // Create subcategories id array
    const subgategoryIds: Array<string> = [];
    subcategories?.forEach((subcategory) => {
      if (subcategory?.id) subgategoryIds.push(subcategory?.id);
    });

    // Create request body
    const model = {
      name,
      brand,
      description,
      price: parseFloat(price),
      euros: parseFloat(euros),
      category_id: category?.id,
      subcategories: subgategoryIds,
      sizes,
      sizes_in_brand_store: Array.from(brandSizes),
    };

    // Make request
    const res = await addModel(model, worker?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Get model data and set it in context
    const modelRes = await getModelById(res.modelId);
    if (!modelRes.success) {
      toast.error(modelRes.msg);
      return;
    }
    appDispatch({ type: "setModelOnView", payload: modelRes.model }); // Set modelOnView
    if (initialModels)
      appDispatch({
        type: "setInitialModels",
        payload: [...initialModels, modelRes.model],
      });
    navigate("/private/models/view"); // Go to the view of that model
  }

  return (
    <div className={divs.pageContainer}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/models" />
        <h1 className={texts.pageTitle.h1}>Añadir Modelo</h1>
      </div>

      {/* Inputs Container */}
      <div className={`${divs.inputsContainer} sm:w-[400px] w-full`}>
        <InputEl>
          <p>Nombre</p>
          <div>
            <input
              placeholder="Escríba aquí"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </InputEl>

        <InputEl>
          <p>Marca</p>
          <div>
            <input
              placeholder="Escríba aquí"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
          </div>
        </InputEl>

        <InputEl>
          <p>Descripción</p>
          <div>
            <input
              placeholder="Escríba aquí"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </InputEl>

        <InputEl>
          <p>Créditos</p>
          <div>
            <input
              placeholder="Escríba aquí"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </InputEl>

        <InputEl>
          <p>Euros</p>
          <div>
            <input
              placeholder="Escríba aquí"
              type="number"
              value={euros}
              onChange={(e) => setEuros(e.target.value)}
            />
          </div>
        </InputEl>

        <InputEl>
          <p>Categoría</p>
          <div>
            <select
              defaultValue=""
              onChange={(e) => {
                // If category is no value option
                if (e.target.value === "") {
                  setCatgory(undefined); // Clean variable
                  return;
                }
                setCatgory(categories?.find((c) => c.id === e.target.value)); // Set category object
              }}
            >
              <option value="">Categoría</option>
              {categories?.map((c) => (
                <option value={c?.id} key={c?.id}>
                  {capitalize(c?.name)}
                </option>
              ))}
            </select>
          </div>
        </InputEl>

        <InputEl>
          <p>Añadir Subcategoría</p>
          <div>
            <select
              defaultValue=""
              onChange={(e) => {
                // Try to find in subcategory is already in list
                const isAlready = !!subcategories?.find(
                  (sub) => sub?.id === e.target.value
                );

                // Validate
                if (subcategories && e.target.value !== "" && !isAlready) {
                  setSubcategories([
                    ...subcategories,
                    getSubcategoryData(e.target.value, allSubcategories),
                  ]);
                }
              }}
            >
              <option value="">Subcategoría</option>
              {allSubcategories?.map((sub) => (
                <option value={sub.id} key={sub.id}>
                  {capitalize(sub.name)}
                </option>
              ))}
            </select>
          </div>
        </InputEl>

        {/* List of subcategories */}
        {subcategories && subcategories.length > 0 && (
          <ul className={styles.subcategoriesList.list}>
            {subcategories?.map((sub) => (
              <li key={sub?.id} className={styles.subcategoriesList.item}>
                <p className={styles.subcategoriesList.text}>
                  {capitalize(sub?.name || "Sin información")}
                </p>
                <button
                  type="button"
                  onClick={() => {
                    const filteredSubcategories = subcategories.filter(
                      (subc) => subc?.id !== sub?.id
                    );
                    setSubcategories(filteredSubcategories);
                  }}
                >
                  <CloseIcon
                    className={styles.subcategoriesList.deleteBtn}
                    style={{ fontSize: "13px" }}
                  />
                </button>
              </li>
            ))}
          </ul>
        )}

        {/* Enter Sizes */}
        <div className={styles.sizes.container}>
          {/* New size input */}
          <InputEl>
            <p>Tallas en Inventario</p>
            <div>
              <input
                placeholder="Talla"
                type="text"
                value={sizeInput}
                onChange={(e) => setSizeInput(e.target.value)}
              />
              <input
                placeholder="Cantidad"
                type="number"
                value={qtyInput}
                onChange={(e) => setQtyInput(e.target.value)}
                className="pl-[20px]"
              />
              <IconButton
                color="success"
                onClick={() => {
                  if (!validateInputs([sizeInput, qtyInput])) {
                    toast.error("Debes llenar Talla y Cantidad");
                    return;
                  }
                  // Add new size and qty
                  const newObj: { [key: string]: number } = {};
                  newObj[sizeInput] = parseFloat(qtyInput);
                  setSizes({ ...sizes, ...newObj });
                  setSizeInput("");
                  setQtyInput("");
                }}
              >
                <AddOutlinedIcon />
              </IconButton>
            </div>
          </InputEl>

          {/* List of sizes */}
          {Object.keys(sizes) && Object.keys(sizes).length > 0 && (
            <div>
              {Object.keys(sizes).map((key) => (
                <div className={styles.sizes.row} key={key}>
                  <p className={styles.sizes.sizeText}>{key}</p>
                  <div className={styles.sizes.qtyDiv}>
                    <p className={styles.sizes.qtyText}>{sizes[key]}</p>
                    <button type="button">
                      <CloseIcon
                        onClick={() => {
                          const newSizes: { [key: string]: number } = {};
                          const filteredKeys = Object.keys(sizes).filter(
                            (k) => k !== key
                          );
                          filteredKeys.forEach((k: string) => {
                            newSizes[k] = sizes[k];
                          });
                          setSizes(newSizes);
                        }}
                        className={styles.subcategoriesList.deleteBtn}
                        style={{ fontSize: "13px" }}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Sizes in Brand */}
        <div className="w-full flex flex-col mt-6">
          <div className="flex items-end">
            <InputEl>
              <p>Tallas de la Marca</p>
              <div>
                <input
                  placeholder="Nueva Talla"
                  value={newBrandSize}
                  onChange={(e) => setNewBrandSize(e.target.value)}
                />
              </div>
            </InputEl>
            <IconButton
              sx={{ ml: 2, mb: 4.2 }}
              onClick={() => {
                if (!validateInputs([newBrandSize])) return;
                const temp = new Set(brandSizes);
                temp.add(newBrandSize);
                setBrandSizes(temp);
                setNewBrandSize("");
              }}
            >
              <AddOutlinedIcon />
            </IconButton>
          </div>

          <div className="flex flex-col">
            {Array.from(brandSizes).map((size: string) => (
              <div
                className="flex justify-between items-center mb-1"
                key={size}
              >
                <p className={styles.subcategoriesList.text}>{size}</p>
                <CloseIcon
                  className="text-red-400"
                  sx={{ fontSize: "13px", ml: 3, cursor: "pointer" }}
                  onClick={() => {
                    const temp = new Set(brandSizes);
                    temp.delete(size);
                    setBrandSizes(temp);
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Button */}
        <ButtonEl
          type="button"
          className="lg w-full mt-[70px]"
          onClick={addModelHandler}
        >
          Añadir Modelo
        </ButtonEl>
      </div>
    </div>
  );
}
