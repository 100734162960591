import { CategoryType, ModelType, SubcategoryType } from "types/Model";
import { OrderType } from "types/Order";
import { AppStateType } from "./context";

// Actions
export type AppActionType =
  | { type: "setInitialModels"; payload: Array<ModelType> | [] | null }
  | { type: "setCategories"; payload: Array<CategoryType> }
  | { type: "setSubcategories"; payload: Array<SubcategoryType> }
  | { type: "setModelOnView"; payload: ModelType }
  | { type: "setOrderOnView"; payload: OrderType | null }
  | { type: "setPrevOrder"; payload: OrderType | null };

// Reducer
export const appReducer = (
  state: AppStateType,
  action: AppActionType
): AppStateType => {
  switch (action.type) {
    // Set the categories of the models
    case "setInitialModels":
      return {
        ...state,
        initialModels: action.payload,
      };

    // Set the categories of the models
    case "setSubcategories":
      return {
        ...state,
        subcategories: action.payload,
      };

    // Set the categories of the models
    case "setCategories":
      return {
        ...state,
        categories: action.payload,
      };

    // Set the model that will be displayed at some page
    case "setModelOnView":
      return {
        ...state,
        modelOnView: action.payload,
      };

    // Set the order that will be displayed at some page
    case "setOrderOnView":
      return {
        ...state,
        orderOnView: action.payload,
      };

    // Set previous order
    case "setPrevOrder":
      return {
        ...state,
        prevOrder: action.payload,
      };

    default:
      return state;
  }
};
