import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";

// Get orders by status
export async function getOrdersByStatus(
  status: string | null | undefined,
  securityID: string | null | undefined
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/orders/status/${status}`;
    const res = await axios.get(url, { params: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return orders
    return {
      success: true,
      orders: res?.data?.orders || [],
    };
  } catch (error) {
    console.log("GET ORDERS BY STATUS ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo las ordenes"),
    };
  }
}

// Get orders by status
export async function getWorkerOrders(workerId: string | null | undefined) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/orders/asigned-by-employee/${workerId}`;
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return orders
    return {
      success: true,
      orders: res?.data?.orders || [],
    };
  } catch (error) {
    console.log("GET ORDERS BY WORKER ID ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo las ordenes"),
    };
  }
}

// Update Support Data
export async function updateIsInSupport(
  orderId: string | null | undefined,
  supportData: any | null | undefined
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/orders/update-support-data`;
    const body = {
      order_id: orderId,
      support_data: supportData,
    };
    const res = await axios.put(url, body);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return orders
    return { success: true };
  } catch (error) {
    console.log("CHANGE IS IN SUPPORT ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error cambiando el estado"),
    };
  }
}

// Get products data
export async function getOrderProducts(productItems: any[] | null | undefined) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/orders/products-data`;
    const body = { product_items: productItems };
    const res = await axios.post(url, body);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return products
    return { success: true, products: res.data.products };
  } catch (error) {
    console.log("CHANGE IS IN SUPPORT ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo los productoss"),
    };
  }
}

export async function updateOrderStatus(
  data: any,
  securityID: string | null | undefined
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/orders/update-order-status`;
    const res = await axios.put(url, data, { params: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    return { success: true };
  } catch (error) {
    console.log("UPDATE ORDER STATUS", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error actualizando la orden"),
    };
  }
}

// Get customer orders
export async function getCustomerOrders(userId: string | null | undefined) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/orders/customers`;
    const res = await axios.get(url, { params: { user_id: userId } });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return products
    return { success: true, orders: res.data.orders };
  } catch (error) {
    console.log("GET CUSTOMER ORDERS ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo las ordenes"),
    };
  }
}

export async function getOrderById(orderId: string | null | undefined) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/orders/get-by-id/${orderId}`;
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return products
    return { success: true, order: res.data.order };
  } catch (error) {
    console.log("GET ORDER BY ID ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo la orden"),
    };
  }
}
