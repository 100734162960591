import { Routes, Route } from "react-router-dom";
import InitialRoute from "routes/InitialRoute";
import PageLayout from "components/Layout/PageLayout";
import Login from "pages/Auth/Login";
import Profile from "pages/Profile/Profile";
import Orders from "pages/Orders/Orders";
import OrderView from "pages/Orders/OrderView";
import Models from "pages/Models/Models";
import ModelView from "pages/Models/ModelView";
import PrivateRoute from "routes/PrivateRoute";
import EditProfile from "pages/Profile/EditProfile";
import ChangePassword from "pages/Profile/ChangePassword";
import AddModel from "pages/Models/AddModel";
import EditModel from "pages/Models/EditModel";
import Register from "pages/Auth/Register";
import ViewImages from "pages/Models/Images/ViewImages";
import AddImages from "pages/Models/Images/AddImages";
import OrderImages from "pages/Models/Images/OrderImages";
import DeleteImages from "pages/Models/Images/DeleteImages";
import ResetPassword from "pages/Auth/ResetPassword";
import PageNotFound from "pages/404";

// Component
export default function Router() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="" element={<InitialRoute />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="reset-password" element={<ResetPassword />} />

      {/* Private Routes */}
      <Route path="private" element={<PrivateRoute />}>
        <Route
          path="profile"
          element={<PageLayout title="Mi Perfil" Page={Profile} />}
        />

        <Route
          path="profile/edit"
          element={<PageLayout title="Mi Perfil" Page={EditProfile} />}
        />

        <Route
          path="profile/change-password"
          element={<PageLayout title="Mi Perfil" Page={ChangePassword} />}
        />

        <Route
          path="orders"
          element={<PageLayout title="Pedidos" Page={Orders} />}
        />

        <Route
          path="orders/view/:orderId"
          element={<PageLayout title="Pedidos" Page={OrderView} />}
        />

        <Route
          path="models"
          element={<PageLayout title="Modelos" Page={Models} />}
        />

        <Route
          path="models/view"
          element={<PageLayout title="Modelos" Page={ModelView} />}
        />

        <Route
          path="models/add-model"
          element={<PageLayout title="Modelos" Page={AddModel} />}
        />

        <Route
          path="models/edit"
          element={<PageLayout title="Modelos" Page={EditModel} />}
        />

        <Route
          path="models/images/view"
          element={<PageLayout title="Modelos" Page={ViewImages} />}
        />

        <Route
          path="models/images/add"
          element={<PageLayout title="Modelos" Page={AddImages} />}
        />

        <Route
          path="models/images/order"
          element={<PageLayout title="Modelos" Page={OrderImages} />}
        />

        <Route
          path="models/images/delete"
          element={<PageLayout title="Modelos" Page={DeleteImages} />}
        />
      </Route>
      <Route path="*" element={<PageNotFound></PageNotFound>} />
    </Routes>
  );
}
