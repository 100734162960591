import { Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext/context";

export default function PrivateRoute() {
  // Get worker
  const { authState } = useAuthContext();
  const { worker } = authState;

  // Render app or go to login
  return worker ? <Outlet /> : <Navigate to="/login" />;
}
