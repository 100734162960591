import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { orderStatus } from "helpers/orderStatus";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { updateIsInSupport, updateOrderStatus } from "actions/OrdersAction";
import toast from "react-hot-toast";
import { useAppContext } from "contexts/AppContext/context";
import { useAuthContext } from "contexts/AuthContext/context";
import { orderStatusKeys, orderStatusNext, supportStatus } from "types/Order";
import { validateInputs } from "helpers/requests";
import { useNavigate } from "react-router-dom";

const styles = {
  sectionTitle:
    "mt-[10px] w-full border-b-[1px] border-b-solid border-b-gd text-start text-gd font-[500] text-[19px] mb-[35px]",
  fields: {
    item: "mb-[30px] h-[50px] flex flex-col justify-center",
    longItem: "mb-[20px] flex flex-col justify-center",
    label: "font-[400] text-[13px] text-gd mb-[3px]",
    value: "",
    redirectItem: "cursor-pointer hover:text-gd transition-all duration-200",
    redirectLabel: "ml-[25px]",
    redirectIcon: "text-gd",
  },
};

export default function OrderActions() {
  const { appState, appDispatch } = useAppContext();
  const { authState } = useAuthContext();
  const [openConfirmStepModal, setOpenConfirmStepModal] = React.useState(false);
  const [openSupportModal, setOpenSupportModal] = React.useState(false);
  const [supportNotes, setSupportNotes] = React.useState("");
  const { worker } = authState;
  const { orderOnView: order, prevOrder } = appState;
  const [deliveryId, setDeliveryId] = React.useState("");
  const [endOfCycle, setEndOfCycle] = React.useState("");
  const [deliveryUrl, setDeliveryUrl] = React.useState("");
  const navigate = useNavigate();

  // Update prev order status
  async function updatePrevOrderStatus() {
    // Update return data
    const extraData: any = {};
    extraData.return_id = deliveryId;
    extraData.return_url = deliveryUrl;
    extraData.return_type = "delivery_with_return";
    extraData.next_order_id = order?.id; // The next order is the current order

    const payload = {
      order_id: prevOrder?.order_id, // Update previous order
      new_status: orderStatusKeys.returning_order,
      from: worker?.id,
      ...extraData,
    };

    const res = await updateOrderStatus(payload, worker?.id);
    return res;
  }

  // Confirm Step
  async function confirmStepHandler() {
    if (!order?.status) {
      toast.error("Hubo un error con la orden, recarga la página");
      return;
    }

    // Delivery validations
    if (order?.status === orderStatusKeys.preparing_order) {
      if (!validateInputs([deliveryId, endOfCycle, deliveryUrl])) {
        toast.error("Debes introducir todos los datos");
        return;
      }
    }

    // Return validations
    if (order?.status === orderStatusKeys.preparing_devolution) {
      if (!validateInputs([deliveryId, deliveryUrl])) {
        toast.error("Debes introducir todos los datos");
        return;
      }
    }

    // If status is assigning worker, update worker id
    const extraData: any = {};
    if (order?.status === orderStatusKeys.assigning_worker)
      extraData.worker_id = worker?.id;

    // If status is preparing order, update delivery data
    if (order?.status === orderStatusKeys.preparing_order) {
      extraData.end_of_cycle = endOfCycle;
      extraData.delivery_id = deliveryId;
      extraData.delivery_url = deliveryUrl;
      extraData.delivery_type = "only_delivery";

      // If there is a previous order, it means it will be delivered together
      if (prevOrder) {
        extraData.prev_order_id = prevOrder?.order_id;
        extraData.delivery_type = "delivery_with_return";

        // Update previous order with this return data
        const prevOrderRes = await updatePrevOrderStatus();
        if (!prevOrderRes.success) {
          toast.error(prevOrderRes.msg);
          return;
        }
      }
    }

    // If status is preparing return, update return data
    if (order?.status === orderStatusKeys.preparing_devolution) {
      extraData.return_id = deliveryId;
      extraData.return_url = deliveryUrl;
      extraData.return_type = "only_return";
    }

    const payload = {
      order_id: order?.id,
      new_status: orderStatusNext[order.status],
      from: worker?.id,
      ...extraData,
    };

    const res = await updateOrderStatus(payload, worker?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Reload order
    if (
      order?.status === orderStatusKeys.preparing_order ||
      order?.status === orderStatusKeys.preparing_devolution
    ) {
      navigate("/private/orders");
    }

    // Update locally new state
    const newStatus = orderStatusNext[order.status];
    const newOrderData = { ...order, status: newStatus };
    appDispatch({
      type: "setOrderOnView",
      payload: newOrderData,
    });
    setOpenConfirmStepModal(false);
    toast.success("Orden actualizada");
  }

  // Set in support handler
  async function setInSupportHandler() {
    // Create support data
    const supportData = {
      support_status: supportStatus.contacting_customer,
      created_by: "worker",
      message: supportNotes,
      contact_method: "",
      contact_value: "",
    };

    // Change status
    const res = await updateIsInSupport(order?.id, supportData);

    // Check for errors
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    toast.success("Soporte actualizado");

    // Update locally support
    appDispatch({
      type: "setOrderOnView",
      payload: { ...order, is_in_support: true, support_data: supportData },
    });
    setOpenSupportModal(false);
  }

  return (
    <div className="xl:w-[34%] w-full shadow-bs4 rounded-[10px] py-[17px] md:px-[30px] px-[20px] flex flex-col items-center relative md:mb-[0px] mb-[30px]">
      <h1 className={styles.sectionTitle}>Estado</h1>
      {!order?.is_in_support && (
        <IconButton
          style={{ position: "absolute", top: "24px", right: "25px" }}
          size="small"
          onClick={() => setOpenSupportModal(true)}
        >
          <ErrorOutlineIcon className="text-gr3" />
        </IconButton>
      )}
      <p className="w-[90%] mb-[25px] font-[500] text-[17px] text-gr1">
        {orderStatus(order?.status).actionLabel}
      </p>

      {/* Delivery data */}
      {(order?.status === orderStatusKeys.preparing_order ||
        order?.status === orderStatusKeys.preparing_devolution) && (
        <div className="text-start border-y-[1px] border-gray-300 pt-5 mt-2 pb-5 mb-9 w-full">
          <p className="text-gd font-[500] mb-2">Referencia</p>
          <p className="mb-4">{order?.id}</p>
          <p className="text-gd font-[500] mb-1">Albarán</p>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={deliveryId}
            onChange={(e) => setDeliveryId(e.target.value)}
          />
          {order?.status === orderStatusKeys.preparing_order && (
            <>
              <p className="text-gd font-[500] mb-1 mt-4">Fin del Ciclo</p>
              <TextField
                variant="outlined"
                type="date"
                size="small"
                fullWidth
                value={endOfCycle}
                onChange={(e) => setEndOfCycle(e.target.value)}
              />
            </>
          )}
          <p className="text-gd font-[500] mb-1 mt-5">
            URL de
            {order?.status === orderStatusKeys.preparing_order && " Envío"}
            {order?.status === orderStatusKeys.preparing_devolution &&
              " Devolución"}
          </p>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={deliveryUrl}
            onChange={(e) => setDeliveryUrl(e.target.value)}
          />
          <p className="text-gd font-[500] mb-2  mt-5">
            Tipo de
            {order?.status === orderStatusKeys.preparing_order && " Envío"}
            {order?.status === orderStatusKeys.preparing_devolution &&
              " Devolución"}
          </p>
          {order?.status === orderStatusKeys.preparing_order && (
            <p>{prevOrder ? "Envío con Devolución" : "Solo Envío"}</p>
          )}
          {order?.status === orderStatusKeys.preparing_devolution &&
            "Solo Devolución"}
        </div>
      )}

      {orderStatus(order?.status).nextBtnLabel && (
        <Button
          sx={{ marginBottom: "20px" }}
          variant="contained"
          onClick={() => setOpenConfirmStepModal(true)}
          disabled={!!order?.is_in_support}
        >
          {orderStatus(order?.status).nextBtnLabel}
        </Button>
      )}

      {/* Confirm Dialog */}
      <Dialog
        open={openConfirmStepModal}
        onClose={() => setOpenConfirmStepModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="font-semibold text-[17px]">
            {orderStatus(order?.status).confirm?.title}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="text-gr1">
              {orderStatus(order?.status).confirm?.text}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpenConfirmStepModal(false)}>
            Cancelar
          </Button>
          <Button onClick={confirmStepHandler} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Support Dialog */}
      <Dialog
        open={openSupportModal}
        onClose={() => setOpenSupportModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="font-semibold text-[17px] text-red-700">
            Soporte de Pedido
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="text-gr1 mb-[20px]">
              ¿Seguro que quieres pasar este pedido a soporte? Quedará bloqueado
              hasta que se resuelva el soporte.
            </div>
            <TextField
              label="Notas"
              variant="outlined"
              rows={2}
              multiline
              fullWidth
              onChange={(e) => setSupportNotes(e.target.value)}
              value={supportNotes}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpenSupportModal(false)}>
            Cancelar
          </Button>
          <Button onClick={setInSupportHandler} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
