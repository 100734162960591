import { SubcategoryType } from "types/Model";

// This functions iterates over the array of categories to find the name of the category that matches the id
export function getSubcategoryName(
  subcategoryId: string,
  subcategories: Array<SubcategoryType> | null
) {
  const result = subcategories?.find(
    (subcategory) => subcategoryId === subcategory.id
  );
  if (result) return result.name;
  return null;
}

// This functions iterates over the array of subcategories to find the data of the category that matches the id
export function getSubcategoryData(
  subcategoryId: string,
  subcategories: Array<SubcategoryType> | null
) {
  return subcategories?.find((subcategory) => subcategoryId === subcategory.id);
}

// This functions iterates over the array of subcategories to find the data of the category that matches the name
export function getSubcategoryDataByName(
  subcategoryName: string,
  subcategories: Array<SubcategoryType> | null
) {
  return subcategories?.find(
    (subcategory) => subcategoryName === subcategory.name
  );
}
