import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext/context";
import { login } from "actions/AuthActions";
import { ButtonEl } from "styledComponents/button";
import { InputEl } from "styledComponents/input";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { validateInputs } from "helpers/requests";
import Toaster from "components/General/Toaster";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { loginPages } from "styleConstants";

// Component
export default function Login() {
  // Variables
  const { authDispatch, authState } = useAuthContext();
  const { worker } = authState;
  const navigate = useNavigate();
  const location = useLocation();

  // Input Values
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  // Login Handler
  function loginHandler() {
    // Validate fields
    if (!validateInputs([email, password])) {
      toast.error("Debes llenar todos los campos");
      return;
    }

    // Login
    login(authDispatch, email, password);
  }

  // Check partner
  React.useEffect(() => {
    if (worker) navigate("/private/profile");
  }, [worker]);

  return (
    <motion.div
      key={location.pathname}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex justify-center w-full"
    >
      <div className="w-full h-[100vh] flex 2xl:max-w-[1080px]">
        {/* Image */}
        <img
          className={loginPages.img}
          src="https://media.vogue.es/photos/6214cb7306f1b0b2496099c1/2:3/w_1920,c_limit/VOGUE_InstagramUMA6.jpg"
          alt=""
        />

        <div className={loginPages.rightSide}>
          <div className={loginPages.formContainer}>
            {/* Page Title */}
            <h1 className={loginPages.h1}>Xerfer Trabajadores</h1>
            <h2 className={loginPages.h2}>Iniciar Sesión</h2>

            {/* Inputs */}
            <InputEl>
              <p>E-mail</p>
              <div>
                <EmailOutlinedIcon className="icon" />
                <input
                  type="text"
                  placeholder="Introduzca e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </InputEl>
            <InputEl>
              <p>Contraseña</p>
              <div>
                <VpnKeyOutlinedIcon className="icon" />
                <input
                  type="password"
                  placeholder="Introduzca contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </InputEl>

            {/* Buttons */}
            <ButtonEl
              type="button"
              onClick={() => loginHandler()}
              className="mt-[40px] mb-[10px]"
            >
              Entrar
            </ButtonEl>

            {/* Label */}
            <p className={loginPages.goTo}>
              ¿Tienes una clave de acceso?{" "}
              <Link to="/register" className="text-gd">
                Registrarse
              </Link>
            </p>
            <p className={loginPages.goTo}>
              <Link to="/reset-password" className="text-gd">
                ¿Olvidaste tu contraseña?
              </Link>
            </p>
          </div>
        </div>

        {/* Toaster */}
        <Toaster />
      </div>
    </motion.div>
  );
}
