import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Chip, IconButton } from "@mui/material";
import toast from "react-hot-toast";
import { getOrderProducts } from "actions/OrdersAction";
import { cutDisplayText } from "helpers/string";

const styles = {
  sectionTitle:
    "mt-[10px] w-full border-b-[1px] border-b-solid border-b-gd text-start text-gd font-[500] text-[19px] mb-[35px]",
  fields: {
    item: "mb-[30px] h-[50px] flex flex-col justify-center",
    longItem: "mb-[20px] flex flex-col justify-center",
    label: "font-[400] text-[13px] text-gd mb-[3px]",
    value: "",
    redirectItem: "cursor-pointer hover:text-gd transition-all duration-200",
    redirectLabel: "ml-[25px]",
    redirectIcon: "text-gd",
  },
};

interface OrderProductItemInterface {
  product: any;
}

function OrderProductItem({ product }: OrderProductItemInterface) {
  // Variables
  const [smallView] = React.useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  return (
    <div className="flex items-center mt-[10px] pb-[10px] border-b-[1px] border-b-solid border-b-gr5 relative">
      <img
        src={product?.image_url || "/imgs/no_img.png"}
        alt="Producto"
        className="w-[70px] h-[70px] object-cover"
      />
      <div className="flex w-full flex-col grow items-start md:ml-[30px] ml-[10px] justify-start">
        <div className="flex items-center mb-[15px]">
          <h2 className="font-[500] text-[15px] text-gd">
            {(smallView ? cutDisplayText(product?.name, 10) : product?.name) ||
              "Sin nombre"}{" "}
          </h2>
          <p className="font-[400] text-[14px] ml-[10px] text-gr3">
            {product?.brand || "Sin marca"}
          </p>
          {product?.not_in_stock && (
            <Chip
              label="No hay en Inventario"
              color="error"
              size="small"
              variant="outlined"
              sx={{ ml: 2, mt: -0.3 }}
            />
          )}
        </div>
        <div className="w-full grid grid-cols-2">
          <p className="text-[14px] text-start">
            Talla: {product?.size || "Sin talla"}
          </p>
          <p className="text-[14px] text-start">Créditos: {product?.price}</p>
        </div>
      </div>
      <IconButton>
        <KeyboardArrowRightIcon color="primary" />
      </IconButton>
    </div>
  );
}

interface OrderProductsInterface {
  productItems: any[] | undefined | null;
}

export default function OrderProducts({
  productItems,
}: OrderProductsInterface) {
  // Variables
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get products data handler
  async function getProductsDataHandler() {
    const res = await getOrderProducts(productItems);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    setProducts(res.products);
    setLoading(false);
  }

  // Get products data
  React.useEffect(() => {
    getProductsDataHandler();
  }, []);

  return (
    <div
      className="shadow-bs4 rounded-[10px] py-[17px] md:px-[30px] px-[20px] mt-[30px]"
      id="productsList"
    >
      <h1 className={styles.sectionTitle}>Productos del Pedido</h1>
      {!loading ? (
        products.map((product: any) => (
          <OrderProductItem product={product} key={product?.name} />
        ))
      ) : (
        <p>Loading</p>
      )}
    </div>
  );
}
