import { Navigate } from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext/context";

export default function InitialRoute() {
  // Get worker
  const { authState } = useAuthContext();
  const { worker } = authState;

  // If there is a worker go to dashboard
  return worker ? (
    <Navigate to="/private/profile" />
  ) : (
    // If there isn't worker go to login
    <Navigate to="/login" />
  );
}
