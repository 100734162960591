import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import BackButton from "components/General/BackButton";
import React from "react";
import { divs, texts } from "styleConstants";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ImageType } from "types/Model";
import { useAppContext } from "contexts/AppContext/context";
import toast from "react-hot-toast";
import { deleteModelImage } from "actions/ModelsActions";
import { useAuthContext } from "contexts/AuthContext/context";

export default function DeleteImages() {
  // Variables
  const { appState, appDispatch } = useAppContext();
  const { modelOnView, initialModels } = appState;
  const { authState } = useAuthContext();
  const { worker } = authState;
  const [imgs, setImgs] = React.useState<Array<ImageType> | null | undefined>(
    modelOnView?.model_imgs
  );
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] =
    React.useState(false);
  const [imageToDelete, setImageToDelete] = React.useState<ImageType | null>(
    null
  );

  // Delete image
  async function deleteImage() {
    if (!imageToDelete || !imageToDelete.bucketPath) {
      toast.error("Hay un problema para eliminar esa imágen");
      return;
    }

    // Make request
    const res = await deleteModelImage(
      modelOnView?.model_id,
      imageToDelete?.bucketPath,
      worker?.id
    );
    if (!res.success) {
      toast.error("Hubo un error al eliminar la imágen");
      return;
    }
    toast.success("Imágen eliminada exitosamente");

    // Filter images locally
    const filteredBucketPaths = modelOnView?.imgs_bucket_paths?.filter(
      (p) => p?.path !== imageToDelete?.bucketPath
    );
    const filteredImages = imgs?.filter(
      (i) => i?.bucketPath !== imageToDelete?.bucketPath
    );
    setImgs(filteredImages); // Set images on the current delete page

    // Change model data in context
    const newData = {
      ...modelOnView,
      imgs_bucket_paths: filteredBucketPaths,
      model_imgs: filteredImages,
    };
    appDispatch({ type: "setModelOnView", payload: newData }); // Set modelOnView
    if (initialModels) {
      // Delete model from context
      const fiteredModels = initialModels?.filter(
        (m) => m.model_id !== modelOnView?.model_id
      );
      // Add new model data again
      appDispatch({
        type: "setInitialModels",
        payload: [...fiteredModels, newData],
      });
    }

    // Close dialog
    setOpenConfirmDeleteModal(false);
  }

  return (
    <div className={divs.pageContainer}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/models/images/view" />
        <h1 className={texts.pageTitle.h1}>Eliminar Imágenes del Modelo</h1>
      </div>

      {/* Actions */}
      <p className="w-[78%] text-gr1 text-[15px] mb-[15px] mt-[10px] text-start">
        Elimina las imágenes que desees.
      </p>

      {/* Images Contnainer */}
      <div className={divs.modelImagesPage.container}>
        <div className={divs.modelImagesPage.grid}>
          {imgs?.map((img) => (
            <div className={divs.modelImagesPage.item} key={img.bucketPath}>
              <IconButton
                className={divs.modelImagesPage.deleteBtn}
                onClick={() => {
                  setOpenConfirmDeleteModal(true);
                  setImageToDelete(img);
                }}
              >
                <HighlightOffIcon />
              </IconButton>
              <img
                src={img?.url || "/imgs/no_img.png"}
                alt="Imagen de Modelo"
                className={divs.modelImagesPage.img}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Confirm Dialog */}
      <Dialog
        open={openConfirmDeleteModal}
        onClose={() => setOpenConfirmDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="font-semibold text-[17px]">Confirmar Eliminar Imágen</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-gr1">
              ¿Estás seguro que quieres eliminar la imágen?
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDeleteModal(false)}>
            Cancelar
          </Button>
          <Button onClick={deleteImage} autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
