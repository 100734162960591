import { InputEl } from "styledComponents/input";
import { ButtonEl } from "styledComponents/button";
import { divs, texts } from "styleConstants";
import BackButton from "components/General/BackButton";
import React from "react";
import { validateInputs } from "helpers/requests";
import toast from "react-hot-toast";
import { changePassword } from "actions/AuthActions";
import { useAuthContext } from "contexts/AuthContext/context";
import { useNavigate } from "react-router-dom";

// Component
export default function ChangePassword() {
  // Variables
  const { authState } = useAuthContext();
  const { worker } = authState;
  const [curPassword, setCurPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const navigate = useNavigate();

  // Change password handler
  async function changePasswordHandler() {
    // Validate fields
    if (!validateInputs([curPassword, newPassword, confirmPassword])) {
      toast.error("Debes llenar todos los campos");
      return;
    }

    // Change password
    const res = await changePassword(
      worker?.email,
      curPassword,
      newPassword,
      confirmPassword
    );
    if (!res.success) {
      toast.error(res.msg || "Hubo un error cambiando la contraseña");
      return;
    }

    // On success
    toast.success("Contraseña actualizada correctamente");
    navigate("/private/profile");
  }

  return (
    <div className={divs.pageContainer}>
      {/* Title */}
      <div className={texts.pageTitle.container}>
        <BackButton />
        <h1 className={texts.pageTitle.h1}>Cambiar Contraseña</h1>
      </div>

      {/* Inputs Container */}
      <div className={divs.inputsContainer}>
        <InputEl>
          <p>Contraseña Anterior</p>
          <div>
            <input
              type="text"
              placeholder="Escribe aquí"
              value={curPassword}
              onChange={(e) => setCurPassword(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Nueva Contraseña</p>
          <div>
            <input
              type="text"
              placeholder="Escribe aquí"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Confirma Contraseña</p>
          <div>
            <input
              type="text"
              placeholder="Escribe aquí"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </InputEl>
      </div>

      {/* Buttons */}
      <ButtonEl type="button" onClick={changePasswordHandler}>
        Guardar Contraseña
      </ButtonEl>
    </div>
  );
}
