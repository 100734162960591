import { WorkerType } from "types/Worker";
import { AuthStateType } from "./context";

// Actions
export type AuthActionType =
  | { type: "login"; payload: WorkerType }
  | { type: "logout" };

// Reducer
export const authReducer = (
  state: AuthStateType,
  action: AuthActionType
): AuthStateType => {
  switch (action.type) {
    /* The login action type changes the partner value in the context */
    case "login":
      return {
        ...state,
        worker: action.payload,
      };

    /* The logout action type set the partner value in the context to null */
    case "logout":
      return {
        ...state,
        worker: null,
      };

    default:
      return state;
  }
};
