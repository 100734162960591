import React, { Dispatch, SetStateAction } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ButtonEl } from "styledComponents/button";
import { InputEl } from "styledComponents/input";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import toast from "react-hot-toast";
import { validateInputs } from "helpers/requests";
import ToasterComp from "components/General/Toaster";
import { userTypeTranslator } from "helpers/translators";
import { useAuthContext } from "contexts/AuthContext/context";
import { register, verifyNewWorkerEmailAndKey } from "actions/AuthActions";
import { motion } from "framer-motion";
import { loginPages } from "styleConstants";

// Key Form Interface
interface KeyFormInterface {
  setKeyAccepted: Dispatch<SetStateAction<boolean>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

// Key Form Component
function KeyFormComponent({
  setKeyAccepted,
  setEmail: setGlobalEmail,
  setType: setGlobalType,
}: KeyFormInterface) {
  const [email, setEmail] = React.useState("");
  const [key, setKey] = React.useState("");

  // Validate access key
  async function validateKey() {
    if (!validateInputs([email, key])) {
      toast.error("Debes llenar todos los campos");
      return;
    }

    const result = await verifyNewWorkerEmailAndKey(key);
    if (result === false) {
      toast.error(
        "Hubo un error validando la clave, o la clave es incorrecta."
      );
    } else {
      if (result.email !== email) {
        toast.error("El correo no corresponde a esa clave");
        return;
      }
      // Validate user type
      if (result.type !== "worker") {
        toast.error(
          "El tipo de usuario no es 'Trabajador', esta es la aplicación de trabajadores"
        );
        return;
      }
      // If everything is correct go next step
      toast.success("Clave validada correctamente");
      setGlobalEmail(email);
      setGlobalType(result.type);
      setKeyAccepted(true);
    }
  }

  return (
    <motion.div
      key="KeyFormComponent"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={loginPages.rightSide}
    >
      <div className={loginPages.formContainer}>
        {/* Title */}
        <h1 className={loginPages.h1}>Xerfer Trabajadores</h1>
        <h2 className={loginPages.h2}>Ingresar Clave</h2>

        {/* Inputs */}
        <InputEl>
          <p>E-mail</p>
          <div>
            <EmailOutlinedIcon className="icon" />
            <input
              type="text"
              placeholder="Introduzca e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Clave de Acceso</p>
          <div>
            <VpnKeyOutlinedIcon className="icon" />
            <input
              placeholder="Introduzca clave de acceso"
              value={key}
              onChange={(e) => setKey(e.target.value)}
            />
          </div>
        </InputEl>

        {/* Button */}
        <ButtonEl
          type="button"
          onClick={validateKey}
          className="mt-[40px] mb-[10px]"
        >
          Continuar
        </ButtonEl>

        {/* Label */}
        <p className={loginPages.goTo}>
          ¿Ya tienes cuenta?{" "}
          <Link to="/login" className="text-gd">
            Inicia Sesión
          </Link>
        </p>
      </div>
    </motion.div>
  );
}

// Main Component
export default function Register() {
  // Variables
  const [keyAccepted, setKeyAccepted] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [type, setType] = React.useState("");
  const location = useLocation();

  return (
    <motion.div
      key={location.pathname}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex justify-center w-full"
    >
      <div className="w-full h-[100vh] flex 2xl:max-w-[1080px]">
        {/* Image */}
        <img
          className={loginPages.img}
          src="https://media.vogue.es/photos/6214cb7306f1b0b2496099c1/2:3/w_1920,c_limit/VOGUE_InstagramUMA6.jpg"
          alt=""
        />

        {/* Righth Side */}
        {!keyAccepted ? (
          <KeyFormComponent
            setKeyAccepted={setKeyAccepted}
            setEmail={setEmail}
            setType={setType}
          />
        ) : (
          <DataFormComponent email={email} type={type} />
        )}

        {/* Toaster */}
        <ToasterComp />
      </div>
    </motion.div>
  );
}

// Key Form Interface
interface DataFormInterface {
  email: string;
  type: string;
}

// Data Form Component
function DataFormComponent({ email, type }: DataFormInterface) {
  // Variables
  const { authDispatch, authState } = useAuthContext();
  const { worker } = authState;
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [phone, setPhone] = React.useState("");

  // Tools
  const navigate = useNavigate();

  // Register Handler
  function registerHandler() {
    // Validate fields
    if (!validateInputs([name, password, confirmPassword, phone])) {
      toast.error("Debes llenar todos los campos");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }
    if (type !== "worker") {
      toast.error(
        "El tipo de usuario no es 'Trabajador', esta es la aplicación de trabajadores"
      );
      return;
    }

    // Register
    register(authDispatch, name, email, password, phone);
  }

  React.useEffect(() => {
    if (worker) navigate("/private/profile");
  }, [worker]);

  return (
    <motion.div
      key="DataFormComponent"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="grow flex items-center flex-col overflow-y-scroll"
    >
      <div className="w-[320px] mt-[5%] mb-[50px]">
        {/* Page Title */}
        <h1 className={`${loginPages.h1} mt-[40px]`}>Ingresar Datos</h1>

        {/* Static Info */}
        <div className={loginPages.staticInfo}>
          <p className={loginPages.label}>Correo Electrónico:</p>
          <p>{email || "email@gmail.com"}</p>
        </div>
        <div className={loginPages.staticInfo}>
          <p className={loginPages.label}>Tipo de Usuario:</p>
          <p>{userTypeTranslator(type) || "Trabajador"}</p>
        </div>

        {/* Inputs */}
        <div className="mt-[30px]">
          <InputEl>
            <p>Nombre</p>
            <div>
              <input
                placeholder="Escriba aquí"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </InputEl>

          <InputEl>
            <p>Contraseña</p>
            <div>
              <input
                type="password"
                placeholder="Escriba aquí"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </InputEl>

          <InputEl>
            <p>Confirmar Contraseña</p>
            <div>
              <input
                type="password"
                placeholder="Escriba aquí"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </InputEl>

          <InputEl>
            <p>Celular o Teléfono</p>
            <div>
              <input
                placeholder="Escriba aquí"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </InputEl>
        </div>

        {/* Button */}
        <ButtonEl
          type="button"
          onClick={registerHandler}
          className="mt-[50px] w-full"
        >
          Crear Cuenta
        </ButtonEl>
      </div>
    </motion.div>
  );
}
